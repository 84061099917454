@charset "UTF-8";
html {
  line-height: 1.15;
  font-family: sans-serif;
  font-size: 100%;
  box-sizing: border-box;
  text-size-adjust: 100%;
  text-decoration-skip-ink: none; }

*, *::before, *::after {
  font-family: inherit;
  font-size: inherit;
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

article,
aside,
footer,
header,
nav,
section {
  display: block; }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
figure,
th,
td {
  margin: 0;
  padding: 0; }

h1 {
  font-size: 2.488rem; }

h2 {
  font-size: 2.074rem; }

h3 {
  font-size: 1.728rem; }

h4 {
  font-size: 1.44rem; }

h5 {
  font-size: 1.2rem; }

h6 {
  font-size: 1rem; }

figcaption,
figure,
main {
  display: block; }

hr {
  height: 0;
  overflow: visible;
  box-sizing: inherit; }

pre {
  overflow: auto;
  font-family: monospace, monospace;
  font-size: inherit; }

a {
  background-color: transparent;
  text-decoration-skip: objects; }

abbr {
  border-bottom: 0;
  text-decoration: none; }

b,
strong {
  font-weight: bolder; }

em,
i {
  line-height: inherit;
  font-style: italic; }

code,
kbd,
samp {
  margin: 0;
  font-family: monospace, monospace;
  font-size: inherit; }

dfn {
  font-style: italic; }

mark {
  background-color: #ffff00;
  color: #000000; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  vertical-align: baseline;
  line-height: 0;
  font-size: 75%; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

ul,
ol,
dl {
  margin-left: 20px;
  list-style-position: outside; }

audio,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

img {
  display: inline-block;
  border-style: none;
  max-width: 100%;
  height: auto;
  min-height: 0.1px;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  line-height: inherit;
  font-family: inherit;
  font-size: 100%; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  border-radius: 0;
  font-family: inherit;
  box-sizing: inherit; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  border: 0;
  border-radius: 0;
  line-height: inherit;
  font-family: inherit;
  appearance: button; }

[type='file']::-webkit-file-upload-button {
  border: 0;
  border-radius: 0;
  line-height: inherit;
  font-family: inherit;
  appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  margin: 0;
  border: 0;
  padding: 0; }

legend {
  display: table;
  float: left;
  padding: 0;
  width: 100%;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  box-sizing: inherit; }
  legend + * {
    clear: left; }

progress {
  display: inline-block;
  vertical-align: baseline; }

textarea {
  display: block;
  border-radius: 0;
  height: auto;
  overflow: auto;
  font-family: inherit; }

[type='checkbox'],
[type='radio'] {
  padding: 0;
  box-sizing: inherit; }

[type='number']::-webkit-inner-spin-button, [type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  outline-offset: -2px;
  appearance: textfield; }

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

details,
menu {
  display: block; }

summary {
  display: list-item; }

canvas {
  display: inline-block; }

template {
  display: none; }

[hidden] {
  display: none; }

address {
  font-style: inherit; }

.kindling-mq {
  font-family: "small[em]=0em&small[px]=0&medium[em]=37.5em&medium[px]=600&large[em]=56.25em&large[px]=900&xlarge[em]=75em&xlarge[px]=1200&xxlarge[em]=100em&xxlarge[px]=1600"; }

html {
  font-size: 100%; }

body {
  font-size: 0.9375rem;
  line-height: 1.6;
  background-color: #ffffff;
  color: #5c5c5c;
  font-family: "montserrat", sans-serif;
  font-weight: 400; }

.is-hidden {
  display: none !important; }

p {
  line-height: 1.6;
  font-size: 0.9375rem;
  font-family: "montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
  color: #5c5c5c; }

h1 {
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  color: #000000;
  font-family: "montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.15;
  letter-spacing: 0.0625rem; }
  @media screen and (min-width: 56.25em) {
    h1 {
      font-size: 1.75rem; } }
  @media screen and (min-width: 56.25em) {
    h1 {
      line-height: 1; } }
  @media screen and (min-width: 56.25em) {
    h1 {
      letter-spacing: 0.125rem; } }

h2 {
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  color: #000000;
  font-family: "montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.1;
  letter-spacing: 0.0625rem; }
  @media screen and (min-width: 56.25em) {
    h2 {
      line-height: 1.5; } }

h3 {
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  color: #000000;
  font-family: "montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.3;
  letter-spacing: 0.0625rem; }

h4 {
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  color: #000000;
  font-family: "montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.0625rem;
  line-height: 1.4;
  letter-spacing: 0.0625rem; }

h5 {
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  color: #000000;
  font-family: "montserrat", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1;
  letter-spacing: 0.03125rem; }

h6 {
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  color: #000000;
  font-family: "montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1;
  letter-spacing: 0; }

hr {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  border-width: 0;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  border-bottom-color: #ece8e0;
  clear: both;
  margin-right: auto;
  margin-left: auto;
  height: 0; }

ul {
  list-style-position: outside;
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  list-style-position: outside;
  margin-left: 1.25rem; }

li {
  font-size: 0.9375rem; }

dl {
  list-style-position: outside; }

dt {
  font-weight: 700; }

cite {
  font-size: 0.8125rem;
  display: block; }
  cite::before {
    content: "— "; }

abbr {
  cursor: help; }

code {
  background-color: #000000;
  padding: 0.125rem 0.3125rem 0.0625rem;
  color: #ffffff;
  font-family: monospace; }

kbd {
  background-color: #000000;
  padding: 0.125rem 0.25rem 0;
  color: #ffffff;
  font-family: monospace; }

a {
  font-size: inherit;
  font-weight: 600;
  text-decoration: underline;
  color: inherit; }
  a:hover, a:focus {
    color: #5c5c5c;
    text-decoration: none; }
  a img {
    border: 0; }

strong,
b {
  line-height: inherit;
  font-weight: 700; }

small {
  font-size: 0.875rem;
  line-height: inherit; }

.screenreader-only {
  position: absolute !important;
  left: -99999px !important;
  margin: -1px !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  clip-path: polygon(0 0) !important; }

.skip-link-container {
  position: relative; }
  .skip-link-container:last-of-type .skip-link {
    bottom: 0; }

.skip-link {
  z-index: 999999999;
  border-width: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #ece8e0;
  font-size: 0.9375rem;
  position: absolute;
  background-color: #000000;
  padding: 1.25rem;
  text-decoration: none; }
  .skip-link:not(:focus) {
    position: absolute !important;
    left: -99999px !important;
    margin: -1px !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    clip-path: polygon(0 0) !important; }
  .skip-link:hover, .skip-link:focus {
    color: #ffffff; }

.content-main .skip-link {
  z-index: 700; }

.has-focus {
  outline: none; }

.show-for-sr {
  position: absolute !important;
  left: -99999px !important;
  margin: -1px !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  clip-path: polygon(0 0) !important; }

@font-face {
  text-rendering: optimizeLegibility;
  font-family: 'montserrat';
  font-weight: 700;
  src: url("https://magento503assets.s3.amazonaws.com/teppermans-gGxpZXBzPUSj/fonts/montserrat-latin-700.woff2"), url("https://magento503assets.s3.amazonaws.com/teppermans-gGxpZXBzPUSj/fonts/montserrat-latin-700.woff"); }

@font-face {
  text-rendering: optimizeLegibility;
  font-family: 'montserrat';
  font-weight: 400;
  src: url("https://magento503assets.s3.amazonaws.com/teppermans-gGxpZXBzPUSj/fonts/montserrat-latin-400.woff2"), url("https://magento503assets.s3.amazonaws.com/teppermans-gGxpZXBzPUSj/fonts/montserrat-latin-400.woff"); }

.maintenance-wrapper {
  background-color: #f7f5f3;
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .maintenance-wrapper .maintenance-content {
    padding: 1.5625rem 0 2.5rem 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 100rem;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-wrap: wrap; }
    @media screen and (min-width: 56.25em) {
      .maintenance-wrapper .maintenance-content {
        padding: 7rem 0 7.6875rem 0; } }
    @media screen and (min-width: 37.5em) {
      .maintenance-wrapper .maintenance-content {
        padding-left: 2rem; } }
    @media screen and (min-width: 56.25em) {
      .maintenance-wrapper .maintenance-content {
        padding-left: 2.5rem; } }
    @media screen and (min-width: 37.5em) {
      .maintenance-wrapper .maintenance-content {
        padding-right: 2rem; } }
    @media screen and (min-width: 56.25em) {
      .maintenance-wrapper .maintenance-content {
        padding-right: 2.5rem; } }
    @media screen and (min-width: 37.5em) {
      .maintenance-wrapper .maintenance-content {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        height: 100%; } }
    .maintenance-wrapper .maintenance-content .hero-subtitle {
      text-transform: uppercase;
      text-rendering: optimizeLegibility;
      color: #231f20;
      font-family: "montserrat", sans-serif;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.3;
      letter-spacing: 0.0625rem;
      margin-bottom: 0.9375rem; }
    .maintenance-wrapper .maintenance-content .hero-title {
      text-transform: uppercase;
      text-rendering: optimizeLegibility;
      color: #000000;
      font-family: "montserrat", sans-serif;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.15;
      letter-spacing: 0.0625rem;
      margin-bottom: 1.5625rem;
      max-width: 29.6875rem; }
      @media screen and (min-width: 56.25em) {
        .maintenance-wrapper .maintenance-content .hero-title {
          font-size: 1.75rem; } }
      @media screen and (min-width: 56.25em) {
        .maintenance-wrapper .maintenance-content .hero-title {
          line-height: 1; } }
      @media screen and (min-width: 56.25em) {
        .maintenance-wrapper .maintenance-content .hero-title {
          letter-spacing: 0.125rem; } }
    .maintenance-wrapper .maintenance-content .additional-image img {
      margin-top: 2.5rem;
      object-fit: contain; }
      @media screen and (min-width: 37.5em) {
        .maintenance-wrapper .maintenance-content .additional-image img {
          margin-top: 0;
          max-height: 18.75rem; } }
      @media screen and (min-width: 75em) {
        .maintenance-wrapper .maintenance-content .additional-image img {
          max-height: 25rem; } }
  .maintenance-wrapper .contacts {
    width: 100%; }
    .maintenance-wrapper .contacts .link {
      display: block;
      font-weight: inherit; }
      @media screen and (min-width: 37.5em) {
        .maintenance-wrapper .contacts .link {
          display: inline; } }
      .maintenance-wrapper .contacts .link:hover, .maintenance-wrapper .contacts .link:focus {
        color: #3a3a3a; }
    .maintenance-wrapper .contacts .body-copy {
      font-size: 0.8125rem;
      color: #3a3a3a; }
      .maintenance-wrapper .contacts .body-copy:not(:last-of-type) {
        padding-bottom: 1rem; }
        @media screen and (min-width: 37.5em) {
          .maintenance-wrapper .contacts .body-copy:not(:last-of-type) {
            padding-bottom: 0.5rem; } }

.header {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  background-color: #ffffff; }
  .header .logo-image {
    max-width: 13.125rem; }
    @media screen and (min-width: 37.5em) {
      .header .logo-image {
        max-width: 16.25rem; } }
