@mixin \project\typography\copy(
    $context: 'typography.copy.default',
    $has-margin: false
) {
    @if $has-margin {
        @include \kindling\typography\apply-margin\paragraph();
    }
    @include \kindling\typography\line-height($context);
    @include \kindling\typography\font-size($context);
    @include \kindling\typography\font-stack($context);
    @include \kindling\typography\font-weight($context);
    @include \kindling\typography\font-style($context);
    @include \kindling\typography\letter-spacing($context);
    text-rendering: $paragraph-text-rendering;
    color: \kindling\color\get($context);

    //a {
    //    @include theme-paragraph-anchor();
    //}
}

@mixin \project\typography\copy\lead(
    $context: 'typography.copy.lead', //temp, change back to lead
    $has-margin: false
) {
    @include \project\typography\copy($context, $has-margin);
}

@mixin \project\typography\copy\small(
    $context: 'typography.copy.small', //temp, change back to small
    $has-margin: false
) {
    @include \project\typography\copy($context, $has-margin);
}

@mixin \project\typography\copy\micro(
    $context: 'typography.copy.micro', //temp, change back to micro
    $has-margin: false
) {
    @include \project\typography\copy($context, $has-margin);
}

@mixin \kindling\typography\paragraph(
    $line-height: $paragraph-line-height // @TODO fix this in Kindling and remove
) {
    @include \project\typography\copy();
}
