@mixin \project\maintenance\page\apply() {
    @font-face {
        text-rendering: optimizeLegibility;
        font-family: 'montserrat';
        font-weight: 700;
        src: url('https://magento503assets.s3.amazonaws.com/teppermans-gGxpZXBzPUSj/fonts/montserrat-latin-700.woff2'), url('https://magento503assets.s3.amazonaws.com/teppermans-gGxpZXBzPUSj/fonts/montserrat-latin-700.woff'); // sass-lint:disable-line no-url-protocols no-url-domains
    }

    @font-face {
        text-rendering: optimizeLegibility;
        font-family: 'montserrat';
        font-weight: 400;
        src: url('https://magento503assets.s3.amazonaws.com/teppermans-gGxpZXBzPUSj/fonts/montserrat-latin-400.woff2'), url('https://magento503assets.s3.amazonaws.com/teppermans-gGxpZXBzPUSj/fonts/montserrat-latin-400.woff'); // sass-lint:disable-line no-url-protocols no-url-domains
    }

    .maintenance-wrapper {
        background-color: \kindling\color\get('maintenance-page.background-color');
        width: 100%;
        height: 100vh;
        overflow: hidden;

        .maintenance-content {
            @include \kindling\spacing\apply('hero-container', $padding-sides: ('all'));
            @include \project\layout\container-padded();
            @include \kindling\grid\row();

            @include \kindling\breakpoint\media-query('medium') {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
                height: 100%;
            }

            .hero-subtitle {
                @include \project\typography\heading($heading-type: 'h3', $font-weight: 'typography.heading.bold', $color: 'typography.heading.lighter');
                margin-bottom: \kindling\value\rem-calc(15);
            }

            .hero-title {
                @include \kindling\typography\heading\h1();
                margin-bottom: \kindling\value\rem-calc(25);
                max-width: \kindling\value\rem-calc(475);
            }

            .additional-image {
                img {
                    @include \kindling\spacing\apply($spacing: 'base.xlarge', $margin-sides: 'top');
                    object-fit: contain;

                    @include \kindling\breakpoint\media-query('medium') {
                        margin-top: 0;
                        max-height: \kindling\value\rem-calc(300);
                    }

                    @include \kindling\breakpoint\media-query('xlarge') {
                        max-height: \kindling\value\rem-calc(400);
                    }
                }
            }
        }

        .contacts {
            width: 100%;

            .link {
                display: block;
                font-weight: inherit;

                @include \kindling\breakpoint\media-query('medium') {
                    display: inline;
                }

                &:hover,
                &:focus {
                    color: \kindling\color\get('maintenance-page.links');
                }
            }

            .body-copy {
                @include \kindling\typography\font-size('typography.small-copy');
                color: \kindling\color\get('maintenance-page.links');

                &:not(:last-of-type) {
                    @include \kindling\spacing\apply('maintenance-page.links', $padding-sides: ('bottom'));
                }
            }
        }
    }

    .header {
        @include \kindling\spacing\apply('maintenance-page.logo-container', $padding-sides: ('all'));
        display: flex;
        justify-content: center;
        background-color: \kindling\color\get('header.logo.background-color');

        .logo-image {
            max-width: \kindling\value\rem-calc(210);

            @include \kindling\breakpoint\media-query('medium') {
                max-width: \kindling\value\rem-calc(260);
            }
        }
    }
}
