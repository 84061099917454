$\kindling\color\palette: (
    'not-set': null,
    'inherit': inherit,
    'transparent': transparent,
    'current-color': currentColor,
    'black': #000000,
    'mine-shaft': #3a3a3a,
    'scorpion-gray': #5c5c5c,
    'thunder-gray': #231f20,
    'thunderbird': #d41916,
    'cinnabar': #e6413e,
    'milano-red': #b5100d,
    'martinique': #2d344f,
    'fiord': #454c65,
    'mirage': #1e2335,
    'silver-chalice': #aaaaaa,
    'satin-linen': #ece8e0,
    'pampas': #f7f5f3,
    'white': #ffffff,
    'fern-green': #4b7e3e,
    'peppermint': #f0f9ee,
    'mallard': #2e4d26,
    'east-bay': #3e4e7e,
    'athens-gray': #edeef2,
    'selective-yellow': #ffb509,
    'off-yellow': #fef8e5,
    'yukon-gold': #805a04,
    'chablis': #fff1f1,
    'totem-pole': #a20f0d,
    'facebook-blue': #4267b2, // Do not change. #4267b2 is required by facebook brand guidelines: https://en.facebookbrand.com/#brand-guidelines-assets,
    'google-gray': #939598
);

$\kindling\color\contexts: (
    'white': 'white',
    'black': 'black',
    'primary': 'mine-shaft',
    'primary-light': 'scorpion-gray',
    'primary-dark': 'thunder-gray',
    'secondary': 'thunderbird',
    'secondary-light': 'cinnabar',
    'secondary-dark': 'milano-red',
    'tertiary': 'martinique',
    'tertiary-light': 'fiord',
    'tertiary-dark': 'mirage',
    'neutral-white': 'white',
    'neutral-lighter': 'pampas',
    'neutral-light': 'satin-linen',
    'neutral': 'silver-chalice',
    'neutral-dark': 'scorpion-gray',
    'neutral-darker': 'mine-shaft',
    'neutral-darkest': 'thunder-gray',
    'neutral-black': 'black',
    'success-light': 'peppermint',
    'success': 'fern-green',
    'success-dark': 'mallard',
    'information': 'east-bay',
    'information-light': 'athens-gray',
    'information-dark': 'martinique',
    'warning': 'selective-yellow',
    'warning-light': 'off-yellow',
    'warning-dark': 'yukon-gold',
    'error': 'thunderbird',
    'error-light': 'chablis',
    'error-dark': 'totem-pole',
    'sale': 'thunderbird',
    'best-seller': 'mine-shaft',
    'new': 'martinique',
    'facebook': 'facebook-blue',
    'google': 'google-gray',
    'body': 'neutral-dark',
    'canada': 'thunderbird',
    'button': (
        'default': (
            'background-color': 'primary-dark',
            'background-color-hover': 'primary',
            'text-color': 'white',
            'text-color-hover': 'white',
            'icon-color': 'white',
            'icon-color-hover': 'white'
        ),
        'secondary': (
            'background-color': 'secondary',
            'background-color-hover': 'secondary-dark',
            'text-color': 'white',
            'text-color-hover': 'white',
            'icon-color': 'white',
            'icon-color-hover': 'white'
        ),
        'ghost': (
            'background-color': 'white',
            'background-color-hover': 'primary-dark',
            'text-color': 'primary-dark',
            'text-color-hover': 'white',
            'icon-color': 'primary-dark',
            'icon-color-hover': 'white'
        ),
        'tertiary': (
            'background-color': 'transparent',
            'background-color-hover': 'transparent',
            'text-color': 'primary-dark',
            'text-color-hover': 'primary-dark'
        ),
        'tertiary-light': (
            'background-color': 'transparent',
            'background-color-hover': 'transparent',
            'text-color': 'white',
            'text-color-hover': 'white'
        ),
        'tertiary-dark': (
            'background-color': 'transparent',
            'background-color-hover': 'transparent',
            'text-color': 'neutral-darkest',
            'text-color-hover': 'neutral-darkest'
        ),
        'disabled': (// @TODO Nate why contexts no work for disabled?
            'background-color': 'neutral-light',
            'text-color': 'neutral-dark',
            'tertiary': (
                'background-color': 'transparent',
                'text-color': 'neutral-dark',
            )
        ),
        'social-login': (
            'facebook': (
                'background-color': 'facebook-blue', // Do not change. Facebook branding guidelines require this blue: https://en.facebookbrand.com/#brand-guidelines-assets
                'background-color-hover': 'facebook-blue', // Do not change. Facebook branding guidelines require this blue: https://en.facebookbrand.com/#brand-guidelines-assets
                'icon-background-color': 'facebook-blue', // Do not change. Facebook branding guidelines require this blue: https://en.facebookbrand.com/#brand-guidelines-assets
                'icon-background-color-hover': 'facebook-blue' // Do not change. Facebook branding guidelines require this blue: https://en.facebookbrand.com/#brand-guidelines-assets
            ),
            'google': (
                'text-color': 'neutral-black',
                'text-color-hover': 'neutral-black',
                'background-color': 'white',
                'background-color-hover': 'white',
                'icon-background-color': 'white', // Do not change. Google branding guidelines require white: https://developers.google.com/identity/branding-guidelines
                'icon-background-color-hover': 'white' // Do not change. Google branding guidelines require white: https://developers.google.com/identity/branding-guidelines
            )
        ),
        'mobile-mega-menu-variation-a': (
            'open-button': (
                'icon-color': 'neutral-white',
                'icon-color-hover': 'neutral-white',
                'background-color': 'secondary',
                'background-color-hover': 'secondary'
            ),
            'back-button': (
                'content': 'neutral-white',
                'content-hover': 'neutral-white',
                'background-color': 'secondary',
                'background-color-hover': 'secondary-dark'
            )
        )
    ),
    'dropdown': (
        'default': (
            'trigger': (
                'text': 'neutral-darker',
                'icon': 'inherit'
            ),
            'content': (
                'box-shadow': 'neutral-black',
                'background': 'neutral-white',
                'text': 'body',
                'title': 'neutral-black'
            ),
            'list': (
                'item': 'neutral-darker'
            )
        ),
        'action-dropdown': (
            'default': (
                'trigger': (
                    'text': 'inherit',
                    'icon': 'inherit'
                )
            )
        )
    ),
    'typography': (
        'hero': (
            'text-shadow': 'neutral-black'
        ),
        'heading': (
            'default': 'neutral-black',
            'lighter': 'thunder-gray'
        ),
        'anchor': (
            'text-color': 'inherit',
            'text-color-hover': 'primary-light'
        ),
        'copy': (
            'default': 'primary-light',
            'lead': 'neutral-darkest',
            'small': 'primary-light',
            'micro': 'primary-light'
        ),
        'ol': (
            'icon': 'neutral-darkest'
        ),
        'figcaption': (
            'background': 'not-set',
            'color': 'not-set'
        )
    ),
    'box': (
        'default': (
            'container': (
                'background-color': 'not-set',
                'text-color': 'not-set'
            ),
            'section': (
                'background-color': 'not-set',
                'text-color': $\kindling\color\foreground-colors
            )
        ),
        'account': (
            'container': (
                'background-color': 'not-set',
                'text-color': $\kindling\color\foreground-colors
            ),
            'section': (
                'background-color': 'neutral-white',
                'text-color': $\kindling\color\foreground-colors
            )
        )
    ),
    'modal': (
        'default': (
            'title': (
                'background-color': 'neutral-lighter',
                'text-color': 'neutral-black'
            ),
            'content': (
                'background-color': 'white',
                'text-color': 'neutral-dark',
                'box-shadow': 'neutral-darkest'
            ),
            'close-button': (
                'background-color': 'transparent',
                'background-color-hover': 'transparent',
                'icon-color': 'primary-dark',
                'icon-color-hover': 'secondary'
            )
        )
    ),
    'accordion': (
        'default': (
            'title': (
                'background-color': 'white',
                'background-color-hover': 'white',
                'text-color': ('white', 'black'),
                'text-color-hover': ('white', 'black')
            ),
            'body': (
                'background-color': 'white',
                'text-color': 'primary'
            )
        ),
        'sidebar': (
            'title': (
                'background-color': 'neutral-lighter',
                'background-color-hover': 'neutral-lighter',
                'text-color': ('white', 'black'),
                'text-color-hover': ('white', 'black')
            ),
            'body': (
                'background-color': 'neutral-lighter',
                'text-color': 'primary-dark'
            )
        ),
        'sidebar-light': (
            'title': (
                'background-color': 'transparent',
                'background-color-hover': 'transparent',
                'text-color': 'primary-dark',
                'text-color-hover': 'primary-dark'
            ),
            'body': (
                'background-color': 'transparent',
                'text-color': 'primary-dark'
            )
        ),
        'account-sidebar': (
            'title': (
                'background-color': 'neutral-lighter',
                'background-color-hover': 'neutral-lighter',
                'text-color': ('white', 'black'),
                'text-color-hover': ('white', 'black')
            ),
            'body': (
                'background-color': 'neutral-lighter',
                'text-color': 'primary-dark'
            )
        ),
        'sidebar-checkout': (
            'title': (
                'background-color': 'neutral-lighter',
                'background-color-hover': 'neutral-lighter',
                'text-color': ('white', 'black'),
                'text-color-hover': ('white', 'black')
            ),
            'body': (
                'background-color': 'white',
                'text-color': 'primary-dark'
            )
        ),
        'footer': (
            'title': (
                'background-color': 'primary-light',
                'background-color-hover': 'primary-light',
                'text-color': ('neutral-lighter', 'black'),
                'text-color-hover': ('neutral-lighter', 'black'),
                'icon-color': 'white'
            ),
            'body': (
                'background-color': 'primary-light',
                'text-color': ('white', 'black')
            )
        ),
        'table': (
            'title': (
                'background-color': 'primary',
                'background-color-hover': 'primary',
                'text-color': ('white', 'primary'),
                'text-color-hover': ('white', 'primary')
            ),
            'body': (
                'background-color': 'white',
                'stripe-background-color': 'neutral-lighter',
                'text-color': ('primary', 'white')
            )
        ),
        'mini-garage': (
            'title': (
                'background-color': 'secondary-dark',
                'background-color-hover': 'secondary-dark',
                'text-color': ('white', 'primary-dark'),
                'text-color-hover': ('white', 'primary')
            ),
            'body': (
                'background-color': 'white',
                'text-color': ('primary', 'white')
            )
        ),
        'vehicle-navigation-form': (
            'title': (
                'background-color': 'secondary',
                'background-color-hover': 'secondary-dark',
                'text-color': ('white', 'primary-dark'),
                'text-color-hover': ('white', 'primary')
            ),
            'body': (
                'background-color': 'secondary',
                'text-color': ('primary', 'white')
            )
        ),
        'compare-bar': (
            'title': (
                'background-color': 'neutral-lighter',
                'background-color-hover': 'neutral-lighter',
                'text-color': ('white', 'black'),
                'text-color-hover': ('white', 'black')
            ),
            'body': (
                'background-color': 'white',
                'text-color': 'primary'
            )
        )
    ),
    'form': (
        'default': (
            'input': (
                'border-color-disabled': 'neutral-light',
                'border-color-hover': 'neutral-darkest',
                'background-color': 'white',
                'background-color-hover': 'white',
                'background-color-disabled': 'neutral-lighter',
                'text-color-disabled': 'neutral-dark',
                'text-color-active': 'neutral-darkest',
                'text-color': 'neutral-darkest',
                'placeholder-color': 'neutral-darker',
                'select-shape-color': 'neutral-dark',
                'checkbox-disabled': 'neutral-dark',
                'select-option': (
                    'text-color': 'neutral-darker',
                    'text-color-hover': 'neutral-darker',
                    'text-color-active': 'neutral-darker',
                    'background-color': 'white',
                    'background-color-hover': 'neutral-lighter',
                    'background-color-active': 'neutral-lighter'
                ),
                'password-icon': 'neutral-black'
            ),
            'checkbox': (
                'icon': 'neutral-black'
            ),
            'switch': (//@TODO remove when kindling docs issue is fixed, or implement
                'control': 'white',
                'background-color': 'neutral-light',
                'background-color-checked': 'neutral-dark'
            ),
            'password': 'neutral',
            'password-meter-background': 'neutral-black',
            'help': 'not-set',
            'error': 'error',
            'legend': (
                'heading': 'primary',
                'description': 'primary'
            )
        ),
        'search': (
            'input': (
                'background-color': 'neutral-white',
                'text-color': 'primary',
                'placeholder-color': 'neutral-black'
            ),
            'autocomplete': (
                'color': 'neutral-black',
                'background': 'neutral-white',
                'background-hover': 'neutral-lighter'
            ),
            'mobile-container': 'neutral-lighter',
            'mobile-container-shadow': 'neutral-lighter',
            'mobile': (
                'submit-icon-color': 'primary',
                'submit-icon-color-hover': 'primary-dark',
                'close-icon-color': 'secondary',
                'close-icon-color-hover': 'secondary-dark'
            )
        )
    ),
    'slider': (
        'base': (
            'arrow': (
                'background-color': 'white',
                'background-color-hover': 'primary',
                'icon-color': 'primary',
                'icon-color-hover': 'white',
                'icon-disabled': 'button.disabled.background-color'
            ),
            'dot': (
                'background-color': 'button.default.background-color',
                'background-color-active': 'button.default.background-color-hover'
            )
        ),
        'product': (
            'arrow': (
                'background-color': 'white',
                'background-color-hover': 'primary',
                'icon-color': 'primary',
                'icon-color-hover': 'white',
                'icon-disabled': 'button.disabled.background-color'
            ),
            'fade': (
                'mask-invisible': 'transparent',
                'mask-visible': 'black'
            ),
            'dot': (
                'foreground': 'primary',
                'background': 'neutral-light'
            )
        ),
        'hero-variation-a': (
            'section': (
                'background': 'neutral-darkest'
            ),
            'progress': (
                'default': 'white'
            ),
            'arrow': (
                'background-color': 'primary',
                'background-color-hover': 'white',
                'icon-color': 'white',
                'icon-color-hover': 'primary',
                'icon-disabled': 'button.disabled.background-color'
            )
        ),
        'hero-variation-b': (
            'section': (
                'background': 'neutral-darkest'
            ),
            'progress': (
                'default': 'white'
            ),
            'arrow': (
                'background-color': 'white',
                'background-color-hover': 'primary',
                'icon-color': 'primary',
                'icon-color-hover': 'white',
                'icon-disabled': 'button.disabled.background-color'
            ),
            'dot': (
                'background-active': 'white'
            )
        )
    ),
    'gallery': (
        'default': (
            'arrow': (
                'background-color': 'button.secondary.background-color',
                'background-color-hover': 'button.secondary.background-color',
                'icon-color': 'button.secondary.icon-color',
                'icon-color-hover': 'button.secondary.icon-color',
            ),
            'zoom': (
                'background-color': 'button.secondary.background-color',
                'background-color-hover': 'button.secondary.background-color-hover',
                'icon-color': 'button.secondary.icon-color',
                'icon-color-hover': 'button.secondary.icon-color-hover'
            ),
            'dot': (
                'background-color': 'button.secondary.background-color',
                'background-color-active': 'button.secondary.background-color-hover'
            ),
            'loader': (
                'background': 'transparent'
            ),
            'play-icon': (
                'icon-color': 'button.secondary.icon-color',
                'icon-color-hover': 'button.secondary.icon-color-hover',
                'background-color': 'button.secondary.background-color',
                'background-color-hover': 'button.secondary.background-color-hover'
            )
        )
    ),
    'header': (
        'outer': (
            'background': 'white'
        ),
        'top': (
            'background': 'primary-light',
            'text': 'neutral-white',
            'dropdown-icon': 'neutral-light',
            'icon': 'neutral',
            'free-ship-message-icon': 'secondary'
        ),
        'promo-bar': (
            'background-color': 'primary',
            'text-color': ('neutral-white', 'neutral-black')
        ),
        'logo': (
            'background-color': 'neutral-white'
        ),
        'bottom': (
            'background-color': 'neutral-lighter'
        )
    ),
    'scroll-bar': (
        'thumb': 'primary',
        'main': 'neutral-lighter'
    ),
    'menu': (
        'default': (
            'header': (
                'close-button': (
                    'icon-color': 'neutral-darkest',
                    'icon-color-hover': 'neutral-dark',
                    'background-color': 'neutral-lighter',
                    'background-color-hover': 'neutral-light'
                ),
                'text': 'neutral-black',
                'background': 'neutral-lighter'
            ),
            'content': (
                'link-list': (
                    'text': 'neutral-black',
                    'text-hover': 'secondary',
                    'icon': 'not-set',
                    'icon-hover': 'not-set',
                    'background': 'not-set',
                    'background-hover': 'not-set'
                )
            )
        ),
        'mobile-mega-menu-variation-a': (
            'accent': 'neutral-darkest',
            'header': (
                'close-button': (
                    'icon-color': 'menu.default.header.close-button.icon-color',
                    'icon-color-hover': 'menu.default.header.close-button.icon-color-hover',
                    'background-color': 'menu.default.header.close-button.background-color',
                    'background-color-hover': 'menu.default.header.close-button.background-color-hover'
                ),
                'text': 'menu.default.header.text',
                'background': 'menu.default.header.background'
            ),
            'content': (
                'link-list': (
                    'text': 'menu.default.content.link-list.text',
                    'text-hover': 'menu.default.content.link-list.text-hover',
                    'icon': 'menu.default.content.link-list.icon',
                    'icon-hover': 'menu.default.content.link-list.icon-hover',
                    'background': 'menu.default.content.link-list.background',
                    'background-hover': 'menu.default.content.link-list.background-hover'
                ),
                'background': (
                    'default': 'neutral-white'
                )
            )
        ),
        'desktopdesktop-mega-menu-variation-a': (
            'accent': 'secondary',
            'neutral': 'neutral',
            'content': (
                'background': 'neutral-white'
            )
        ),
        'desktop-mega-menu-variation-b': (
            'accent': 'secondary',
            'neutral': 'neutral',
            'level-1': (
                'content': (
                    'background': 'neutral-lighter'
                )
            ),
            'level-2': (
                'content': (
                    'background': 'neutral-white'
                ),
                'shadow': 'black',
            )
        )
    ),
    'pullout-menu': (
        'default': (
            'background': 'neutral-white'
        )
    ),
    'tabs': (
        'default': (
            'title': (
                'text-color': 'neutral-darkest',
                'text-color-hover': 'neutral-darkest',
                'background-color-hover': 'not-set',
            ),
            'panel': 'not-set'
        )
    ),
    'reviews': (
        'default': (
            'rating': 'primary',
            'action': 'primary',
            'action-active': 'typography.anchor.text-color-hover',
            'form': (
                'focus-indicator': 'primary'
            )
        )
    ),
    'pdp': (
        'product-info': (
            'add-to-cart': (
                'background': 'neutral-white',
                'quantity': 'neutral-lighter'
            )
        )
    ),
    'product-price': (
        'default': (
            'price': 'primary',
            'special-price': 'secondary',
            'old-price': 'primary'
        )
    ),
    'checkout': (
        'sidebar-totals': (
            'row-alternate': 'neutral-lighter'
        ),
        'footer': (
            'background-color': 'neutral-darkest',
            'text-color': 'neutral-white',
            'logo': (
                'background-color': 'neutral-white'
            )
        )
    ),
    'minicart': (
        'background-color': 'neutral-white',
        'actions': (
            'background-color': 'neutral-white'
        ),
        'totals': (
            'background-color': 'neutral-lighter'
        ),
        'header': (
            'background': 'neutral-lighter',
            'close-button': (
                'background-color': 'transparent',
                'background-color-hover': 'transparent',
                'icon-color': 'neutral-black',
                'icon-color-hover': 'neutral-black'
            ),
            'icon': 'secondary'
        ),
        'trigger': (
            'icon': 'black',
            'icon-mobile': 'secondary',
            'background': 'secondary',
            'background-mobile': 'white',
            'count': (
                'background-color': 'not-set',
                'color': 'white',
                'color-mobile': 'black'
            )
        )
    ),
    'page-banner': (
        'foreground': 'neutral-white',
        'hero': (
            'text-color': 'neutral-white',
            'icon': 'secondary'
        )
    ),
    'overlay': (
        'default': 'neutral-darkest'
    ),
    'contact': (
        'sidebar': (
            'background': 'neutral-white'
        )
    ),
    'skip-link': (
        'default': (
            'background': 'neutral-black',
            'text': 'neutral-white'
        )
    ),
    'badge': (
        'default': (
            'background': 'neutral-black',
            'text': 'neutral-white'
        ),
        'account-sales-title': (
            'background': 'secondary'
        )
    ),
    'table': (
        'default': (
            'thead': (
                'color': 'white',
                'background-color': 'primary'
            ),
            'tbody': (
                'tr': (
                    'background-color': 'white',
                )
            )
        ),
        'secondary': (
            'tbody': (
                'tr': (
                    'background-color': 'neutral-lighter',
                )
            )
        )
    ),
    'item-actions': (
        'default': (
            'text-color': 'primary-dark',
            'text-color-hover': 'secondary'
        )
    ),
    'cta': (
        'overlapping-image-cta': (
            'background': 'neutral-black',
            'content': 'neutral-white'
        )
    ),
    'footer': (
        'bottom': (
            'copyright': 'neutral-light',
            'link-headings': 'neutral-light'
        ),
        'contact': (
            'background-color': 'secondary',
            'form-background-color': 'secondary-dark',
            'color': 'neutral-white'
        ),
        'pre-block': (
            'background-color': 'neutral-lighter'
        ),
        'information': (
            'background-color': 'neutral-white',
            'color': 'black'
        ),
        'additional': (
            'background-color': 'primary-light',
            'text-color': 'neutral-white'
        )
    ),
    'banner': (
        'default-background': 'primary'
    ),
    'maintenance-page': (
        'background-color': 'pampas',
        'logo-box-shadow': 'neutral-black',
        'links': 'mine-shaft'
    ),
    'shop-category-list': (
        'default': (
            'text': 'neutral-white',
            'overlay': 'neutral-black'
        )
    ),
    'messages': (
        'default': (
            'success': (
                'icon': 'success',
                'text': 'success-dark',
                'background': 'success-light',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'error': (
                'icon': 'error',
                'text': 'error-dark',
                'background': 'error-light',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'information': (
                'icon': 'information',
                'text': 'information-dark',
                'background': 'information-light',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'warning': (
                'icon': 'warning',
                'text': 'warning-dark',
                'background': 'warning-light',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            )
        )
    ),
    'category-list': (
        'default': (
            'text': 'primary',
            'text-active': 'secondary'
        )
    ),
    'featured-brands-list': (
        'default': (
            'overlay': 'neutral-white'
        )
    ),
    'brands-page': (
        'desktop-nav': (
            'section-background': 'neutral-white',
            'link': (
                'text': 'not-set',
                'text-hover': 'neutral-white',
                'background': 'not-set',
                'background-hover': 'neutral-black',
            )
        ),
        'sticky-on-page-nav': (
            'active-link': 'neutral-white',
            'active-link-background': 'neutral-black'
        )
    ),
    'badges': (
        'default': (
            'phone': (
                'icon': 'primary-dark',
                'text': 'primary-dark',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'secure': (
                'icon': 'primary-dark',
                'text': 'primary-dark',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'support': (
                'icon': 'primary-dark',
                'text': 'primary-dark',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'returns': (
                'icon': 'primary-dark',
                'text': 'primary-dark',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'currency': (
                'icon': 'primary-dark',
                'text': 'primary-dark',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            )
        )
    ),
    'swatches': (
        'attribute-label': 'neutral-black',
        'swatch': (
            'text-color': 'primary',
            'highlight': 'primary'
        )
    ),
    'email': (
        'wrapper': 'neutral-lighter',
        'border': 'neutral-lighter',
        'header': (
            'background-color': 'white'
        ),
        'footer': (
            'background-color': 'primary-light'
        ),
        'link': (
            'default': (
                'text-color': 'black',
                'text-color-hover': 'primary'
            ),
            'footer': (
                'text-color': 'white',
                'text-color-hover': 'neutral-light'
            )
        ),
        'button': (
            'background-color': 'secondary',
            'background-color-hover': 'secondary-dark',
            'text-color': 'white',
            'text-color-hover': 'white',
        ),
        'address': 'white'
    ),
    'amasty-promo': (
        'cart-notification': (
            'gift-icon': 'neutral-darkest',
            'strong': 'neutral-darkest',
            'view-options': 'neutral-darkest'
        )
    ),
    'article-card': (
        'background': 'white',
        'content': (
            'body': 'scorpion-gray',
        )
    )
);
