$\kindling\typography\font-size\palette: (
    'not-set': null,
    'inherit-value': inherit,
    'tiny': (
        '1': \kindling\value\rem-calc(8),
        '2': \kindling\value\rem-calc(9),
        '3': \kindling\value\rem-calc(10)
    ),
    'icon': (
        '1': \kindling\value\rem-calc(9)
    ),
    'micro': (
        '1': \kindling\value\rem-calc(11),
        '2': \kindling\value\rem-calc(12)
    ),
    'small': (
        '1': \kindling\value\rem-calc(13),
        '2': \kindling\value\rem-calc(14),
        '3': \kindling\value\rem-calc(15)
    ),
    'normal': (
        '1': \kindling\value\rem-calc(16),
        '2': \kindling\value\rem-calc(17),
        '3': \kindling\value\rem-calc(18),
    ),
    'large': (
        '1': \kindling\value\rem-calc(20),
        '2': \kindling\value\rem-calc(22),
        '3': \kindling\value\rem-calc(24),
        '4': \kindling\value\rem-calc(28),
        '5': \kindling\value\rem-calc(30),
        '6': \kindling\value\rem-calc(36),
        '7': \kindling\value\rem-calc(38),
        '8': \kindling\value\rem-calc(40),
        '9': \kindling\value\rem-calc(48),
        '10': \kindling\value\rem-calc(68)
    ),
    'banner': (
        'hero-icon': \kindling\value\px-to-em(22)
    )
);

$\kindling\typography\font-size\contexts: (
    'base': 'small.3',
    'body': 'base',
    'button': (
        'micro': 'tiny.3',
        'small': 'micro.2',
        'regular': 'small.2',
        'large': 'small.3',
        'tertiary': (
            'micro': 'tiny.3',
            'small': 'micro.2',
            'regular': 'small.1',
            'large': 'small.3'
        )
    ),
    'typography': (
        'hero': 'large.6',
        'h1': (
            'small': 'large.3',
            'large': 'large.4'
        ),
        'h2': 'large.2',
        'h3': 'normal.3',
        'h4': 'normal.2',
        'h5': 'normal.1',
        'h6': 'small.3',
        'subheading': (
            'large': 'large.1',
            'medium': 'normal.3',
            'small': 'normal.1'
        ),
        'small-copy': 'small.1',
        'micro-copy': 'micro.1',
        'copy': (
            'micro': 'micro.1',
            'small': 'small.1',
            'default': 'small.3',
            'lead': 'normal.3'
        ),
        'li': 'small.3',
        'anchor': 'inherit-value',
        'cite': 'small.1',
        'small-element': 'small.2',
        'blockquote': (
            'content': 'small.3',
            'author': 'small.3'
        )
    ),
    'customer': (
        'account': (
            'sidebar': (
                'heading': 'large.1',
                'link': 'normal.1'
            )
        ),
        'account-entry': (
            'required-fields': 'micro.1'
        )
    ),
    'checkout': (
        'sidebar-totals': (
            'default': 'normal.1',
            'delete-action': 'small.1'
        ),
        'items': (
            'default': 'large.1'
        ),
        'header': (
            'icon': 'large.1',
            'text': 'small.2'
        )
    ),
    'tooltip': (
        'default': (
            'text': 'small.2'
        )
    ),
    'accordion': (
        'default': (
            'title': 'large.1'
        ),
        'table': (
            'title': 'normal.3'
        ),
        'mini-garage': (
            'title': 'normal.1'
        ),
        'vehicle-navigation-form': (
            'title': 'large.2',
            'dropdown-title': 'normal.1'
        )
    ),
    'form': (
        'default': (
            'input': 'small.2',
            'input-micro': 'small.1',
            'error': 'tiny.3',
            'help': 'small.1',
            'password': 'tiny.3',
            'password-icon': 'large.1',
            'icon': 'small.1',
            'checkbox': (
                'icon': 'large.1'
            ),
            'radio': (
                'icon': 'large.1'
            )
        ),
        'search': (
            'input': 'small.3',
            'icon': 'large.1',
            'mobile-toggle': 'large.4',
            'autocomplete': 'small.2'
        )
    ),
    'sneaky-label': 'tiny.1',
    'reviews': (
        'default': (
            'icon': 'normal.2',
            'action': 'small.1'
        ),
        'pdp': (
            'icon': 'normal.3',
            'action': 'normal.1',
            'list': (
                'date': 'typography.copy.small',
                'summary': 'typography.copy.default',
                'copy': 'typography.copy.default'
            ),
            'form': (
                'required-label': 'small.1',
                'field': 'typography.copy.small',
                'label': 'micro.2',
                'rating': 'large.4'
            )
        )
    ),
    'pdp': (
        'product-info': (
            'product-info-top': 'small.1',
            'add-to-cart': (
                'label': 'normal.3'
            ),
            'stock': (
                'icon': 'small.1'
            ),
            'social-link': (
                'text': 'normal.1',
                'icon': 'normal.3'
            ),
            'currency': (
                'label': 'small.2',
                'icon': 'normal.2'
            )
        )
    ),
    'product-price': (
        'default': (
            'price': 'large.1',
            'special-price': 'product-price.default.price',
            'old-price': 'normal.3',
            'out-of-stock': 'normal.1',
            'msrp': 'small.2'
        ),
        'small': (
            'price': 'small.3',
            'special-price': 'product-price.small.price',
            'old-price': 'micro.2',
            'msrp': 'micro.2'
        ),
        'large': (
            'price': 'large.1',
            'special-price': 'product-price.default.price',
            'old-price': 'normal.3',
            'out-of-stock': 'normal.1',
            'msrp': 'small.2'
        ),
        'checkout': (
            'price': 'body',
            'special-price': 'product-price.checkout.price',
            'old-price': 'micro.1',
            'msrp': 'micro.1'
        )
    ),
    'badge': 'tiny.3',
    'minicart': (
        'item': (
            'default': 'body',
            'name': 'small.2',
            'attribute-label': 'micro.2',
            'attribute-value': 'micro.2',
            'action': (
                'icon': 'small.2',
                'link': 'micro.2'
            )
        ),
        'trigger': (
            'icon': (
                'small': 'large.4',
                'large': 'large.4'
            ),
            'count': 'micro.2'
        ),
        'header': (
            'count': 'small.2'
        )
    ),
    'cart': (
        'item': (
            'action': (
                'text': (
                    'small': 'small.1',
                    'medium': 'typography.copy.default'
                ),
                'icon': (
                    'edit': (
                        'small': 'normal.3'
                    ),
                    'delete': (
                        'small': 'normal.3'
                    ),
                    'wishlist': (
                        'small': 'normal.3'
                    ),
                    'primary': (
                        'small': 'normal.3',
                        'medium': 'large.2'
                    )
                )
            ),
            'total': 'typography.copy.default',
            'quantity-divider': 'large.1',
            'default': 'large.1'
        ),
        'sidebar': (
            'coupon': (
                'cta-text': 'typography.copy.small',
                'list': (
                    'text': 'small.1',
                    'icon': 'large.1'
                ),
                'applied': 'small.2',
                'promo-text': 'normal.1',
                'cancel': 'normal.3'
            ),
            'shipping': (
                'legend': 'typography.copy.small',
                'label': 'typography.copy.small'
            ),
            'credit': (
                'link': 'small.1'
            )
        ),
        'actions': (
            'submit': 'typography.copy.default'
        )
    ),
    'compare-bar': (
        'accordion-title-text': (
            'small': 'accordion.default.title'
        )
    ),
    'compare-page': (
        'item': (
            'name': 'small.3'
        )
    ),
    'contact': (
        'sidebar': (
            'content': 'small.1'
        ),
        'required-note': 'micro.2'
    ),
    'dropdown': (
        'default': (
            'trigger': (
                'text': 'small.1',
                'icon': 'micro.2'
            ),
            'list': (
                'item': 'small.1'
            ),
            'content': (
                'title': 'small.3'
            )
        ),
        'action-dropdown': (
            'default': (
                'trigger': (
                    'text': 'inherit-value',
                    'icon': 'inherit-value'
                )
            )
        )
    ),
    'header': (
        'promo-bar': 'small.2',
        'top': (
            'text': 'micro.2',
            'free-ship-message': 'small.2',
            'icon': (
                'free-ship-icon': 'normal.1',
                'account-icon': 'normal.1',
                'phone-icon': 'small.2',
                'garage-icon': 'normal.2',
                'minicart-icon': 'large.1'
            )
        )
    ),
    'table': (
        'default': (
            'thead': 'large.1',
            'tbody': 'normal.1'
        ),
        'secondary': (
            'thead': 'normal.3',
            'tbody': 'normal.1'
        ),
        'mobile': (
            'thead': 'normal.1',
            'tbody': 'normal.1'
        )
    ),
    'item-actions': (
        'default': (
            'text': 'normal.3',
            'icon': 'large.1'
        )
    ),
    'tabs': (
        'default': (
            'title': (
                'text': 'small.1',
                'icon': 'small.3'
            )
        )
    ),
    'menu': (
        'default': (
            'header': (
                'text': 'small.3'
            ),
            'content': (
                'link-list': (
                    'text': 'small.1',
                    'icon': 'micro.2'
                )
            )
        ),
        'desktop-mega-menu-variation-a': (
            'level-2-link': 'normal.1'
        ),
        'desktop-mega-menu-variation-b': (
            'level-2-link': 'normal.1'
        )
    ),
    'my-garage': (
        'mobile-toggle': 'large.4'
    ),
    'breadcrumb': 'small.2',
    'footer': (
        'small-text': 'small.2',
        'heading': 'small.3',
        'nav-link': 'normal.1',
        'tiny-link': 'micro.2',
        'social-icon': 'large.2'
    ),
    'brands-page': (
        'desktop-nav': (
            'link': (
                'large': 'micro.2',
                'xlarge': 'small.2'
            ),
        ),
        'sticky-on-page-nav': (
            'link': 'micro.2'
        ),
        'brand-name': (
            'small': 'normal.1',
            'xlarge': 'normal.3'
        )
    ),
    'swatches': (
        'more-label': 'typography.copy.micro',
        'attribute-label': 'typography.copy.small'
    ),
    'amasty-promo': (
        'cart-notification': (
            'gift-icon': 'large.3',
            'view-options': 'micro.2'
        )
    ),
    'article-card': (
        'content': (
            'header': 'normal.3',
            'body': 'small.1',
            'read-more': 'small.1'
        )
    )
);
