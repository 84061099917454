$\kindling\typography\line-height\palette: (
    'not-set': null,
    'inherit-value': inherit,
    'hero': 0.9,
    'base': 1,
    'xsmall': 1.1,
    'small': 1.15,
    'medium': 1.3,
    'large': 1.4,
    'xlarge': 1.5,
    'xxlarge': 1.6
);

$\kindling\typography\line-height\contexts: (
    'body': 'xxlarge',
    'typography': (
        'hero': 'base',
        'h1': (
            'small': 'small',
            'large': 'base'
        ),
        'h2': (
            'small': 'xsmall',
            'large': 'xlarge'
        ),
        'h3': 'medium',
        'h4': 'large',
        'h5': 'xsmall',
        'h6': 'base',
        'anchor': 'not-set',
        'subheading': (
            'large': 'small',
            'medium': 'small',
            'small': 'small'
        ),
        'copy': (
            'default': 'body',
            'lead': 'xlarge',
            'small': 'large',
            'micro': 'xlarge'
        )
    ),
    'button': (
        'default': 'base',
        'small': 'small',
        'micro': 'large'
    ),
    'accordion': (
        'default': (
            'title': 'base'
        )
    ),
    'form': (
        'default': (
            'input': 'small'
        ),
        'search': (
            'input': 'xlarge'
        ),
        'newsletter': (
            'signup-button': 'large'
        )
    ),
    'reviews': (
        'default': (
            'short-summary': (
                'small': 'small'
            )
        )
    ),
    'badge': 'medium',
    'dropdown': (
        'default': (
            'list': (
                'anchor': 'small'
            )
        ),
        'pagination': (
            'list': (
                'anchor': 'base'
            )
        )
    ),
    'header': (
        'promo-bar': 'base',
        'icon': 'base'
    ),
    'tooltip': (
        'default': (
            'text': 'small'
        )
    ),
    'minicart': (
        'item': (
            'name': 'xlarge',
            'attributes': 'small'
        )
    ),
    'swatches': (
        'more-label': 'small'
    )
);
