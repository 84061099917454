$\kindling\typography\font-weight\palette: (
    'not-set': null,
    //'thin': 100,
    //'extra-light': 200,
    //'light': 300,
    'normal': 400,
    'medium': 500,
    'semi-bold': 600,
    'bold': 700,
    //'extra-bold': 800,
    //'black': 900
);

$\kindling\typography\font-weight\contexts: (
    'body': 'normal',
    'typography': (
        'heading': (
            'default': 'normal',
            'bold': 'bold'
        ),
        'anchor': 'semi-bold',
        'copy': (
            'default': 'normal',
            'bold': 'bold',
            'lead': 'medium',
            'small': 'normal',
            'micro': 'normal'
        ),
        'ol': (
            'icon': 'bold'
        )
    ),
    'button': (
        'default': 'semi-bold',
        'tertiary': 'bold'
    ),
    'accordion': (
        'default': (
            'title': 'normal'
        )
    ),
    'form': (
        'default': (
            'legend': 'bold',
            'input': 'medium',
            'active': 'medium',
            'normal': 'normal',
            'label': 'semi-bold'
        ),
        'search': (
            'placeholder': 'medium'
        )
    ),
    'footer': (
        'links': 'medium'
    ),
    'checkout': (
        'success': (
            'price': 'normal',
            'total-price': 'bold',
            'item-price': 'medium',
            'method': 'normal'
        )
    ),
    'article-card': (
        'content': (
            'header': 'bold',
            'body': 'normal',
            'read-more': 'bold'
        )
    )
);
