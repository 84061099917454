$\kindling\z-index\palette: (
    'header': 900,
    'header-main': 900,
    'modal-overlay': 1500,
    'modal-content': 1600,
    'pullout-menu-overlay': 1150,
    'pullout-menu-content': 1200,
    ////'sticky-nav': 850,
    'footer-sticky': 910,
    //'product-actions-fixed': 800,
    'product-card-action': 10,
    'above-next-sibling': 10,
    'above-all-siblings': 100,
    'above-sibling': 1,
    'neutral-sibling': 0,
    'below-sibling': -1,
    'below-all-siblings': -999999,
    'skip-link': 999999999,
    'content-skip-link': 700
);
