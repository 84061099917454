@mixin \kindling\apply-screenreader-styles\extras($extras: ()) {
    @include \project\skip-link\apply();

    .has-focus {
        outline: none;
    }

    .show-for-sr {
        @include \kindling\visually-hidden\apply();
    }
}
