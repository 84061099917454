@mixin \project\layout\panel-main($responsive-sizes: $\project\layout\panel-main\widths) {
    @include \kindling\grid\column($responsive-sizes);
}

@mixin \project\layout\panel-left($responsive-sizes:  $\project\layout\panel-left\widths) {
    @include \kindling\grid\column($responsive-sizes, $flex-grow: 0);
}

@mixin \project\layout\panel-right($responsive-sizes:  $\project\layout\panel-right\widths) {
    @include \kindling\grid\column($responsive-sizes, $flex-grow: 0);
}

@mixin \project\layout\container-padded(
    $max-width: $global-max-width,
    $column-count: $\project\layout\container\column-count
) {
    @include \project\layout\container($max-width, $column-count);
    @include \project\layout\column-padding();
}

@mixin \project\layout\container(
    $max-width: $global-max-width,
    $column-count: $\project\layout\container\column-count
) {
    @include \kindling\grid\max-width-container();

    @each $breakpoint, $column in $column-count {
        @include \kindling\breakpoint\media-query($breakpoint) {
            width: \kindling\grid\column-width-calc($column);
        }
    }
}

@mixin \project\layout\column-padding(
    $padding-sides: ('left', 'right')
) {
    @include \kindling\spacing\apply(
        $spacing: 'constrained-content-container.default',
        $padding-sides: $padding-sides
    );
}

@mixin \project\layout\constrained-section-layout-styles(
    $outer-container-class: '.container',
    $inner-container-class: '.container-constrained',
    $breakpoint: 'xlarge',
    $inner-desktop-spacing: 0,
    $column-count: ('small': 12, 'xlarge': 10)
) {
    #{$outer-container-class} {
        @include \project\layout\container-padded();

        @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($breakpoint) 'down') {
            padding-right: 0;
            padding-left: 0;
        }

        #{$inner-container-class} {
            @include \project\layout\container($column-count: $column-count);
            @include \project\layout\column-padding();

            @include \kindling\breakpoint\media-query($breakpoint) {
                padding-right: $inner-desktop-spacing;
                padding-left: $inner-desktop-spacing;
            }
        }
    }
}

@mixin \project\layout\section-overlap($top-section-selector, $bottom-section-selector, $overlap) {
    @if $top-section-selector {
        #{$top-section-selector} {
            // Make space for overlapping section
            &::after {
                @include \kindling\property\apply('height', $overlap);
                display: block;
                width: 1px; // sass-lint:disable-line property-units
                content: '';
            }
        }
    }

    @if $bottom-section-selector {
        #{$bottom-section-selector} {
            @include \kindling\property\apply('margin-top', $overlap, -1);
            position: relative;
            z-index: \kindling\z-index\get('above-sibling');
        }
    }
}
